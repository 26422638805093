import React from 'react'

import One from "./buttons/2022/One"
import Two from "./buttons/2022/Two"
import Three from "./buttons/2022/Three"
import Four from "./buttons/2022/Four"
import Five from "./buttons/2022/Five"
import Six from "./buttons/2022/Six"
import Seven from "./buttons/2022/Seven"
import Eight from "./buttons/2022/Eight"
import Nine from "./buttons/2022/Nine"
import Ten from "./buttons/2022/Ten"
import Eleven from "./buttons/2022/Eleven"
import Twelve from "./buttons/2022/Twelve"
import Thirteen from "./buttons/2022/Thirteen"
import Forteen from "./buttons/2022/Forteen"

import Oneupdated from "./buttons/2023/Oneupdated"
import Twoupdated from "./buttons/2023/Twoupdated"
import Threeupdated from "./buttons/2023/Threeupdated"
import Fourupdated from "./buttons/2023/Fourupdated"
import Fiveupdated from "./buttons/2023/Fiveupdated"
import Sixupdated from "./buttons/2023/Sixupdated"
import Sevenupdated from "./buttons/2023/Sevenupdated"
import Eightupdated from "./buttons/2023/Eightupdated"
import Nineupdated from "./buttons/2023/Nineupdated"
import Tenupdated from "./buttons/2023/Tenupdated"
import Elevenupdated from "./buttons/2023/Elevenupdated"
import Twelveupdated from "./buttons/2023/Twelveupdated"
import Thirteenupdated from "./buttons/2023/Thirteenupdated"
import Forteenupdated from "./buttons/2023/Forteenupdated"

import Oneupdated24 from "./buttons/2024/Oneupdated24"
import Twoupdated24 from "./buttons/2024/Twoupdated24"
import Threeupdated24 from "./buttons/2024/Threeupdated24"
import Fourupdated24 from "./buttons/2024/Fourupdated24"
import Fiveupdated24 from './buttons/2024/Fiveupdated24'
import Sixupdated24 from "./buttons/2024/Sixupdated24"
import Sevenupdated24 from "./buttons/2024/Sevenupdated24"
import Eightupdated24 from "./buttons/2024/Eightupdated24"
import Nineupdated24 from "./buttons/2024/Nineupdated24"
import ZeroNineupdate24 from "./buttons/2024/ZeroNineupdated24"
import Tenupdated24 from "./buttons/2024/Tenupdated24"
import Elevenupdated24 from "./buttons/2024/Elevenupdated24"
import Twelveupdated24 from "./buttons/2024/Elevenupdated24"
import Thirteenupdated24 from "./buttons/2024/Thirteenupdated24"
import Forteenupdated24 from "./buttons/2024/Forteenupdated24"

import Oneupdated25 from "../components/buttons/2025/Oneupdated25"
import Twoupdated25 from "../components/buttons/2025/Twoupdated25"
import Threeupdated25 from "../components/buttons/2025/Threeupdated25"
import Fourupdated25 from "../components/buttons/2025/Fourupdated25"
import Fiveupdated25 from "../components/buttons/2025/Fiveupdated25"
import Sixupdated25 from "../components/buttons/2025/Sixupdated25"
import Sevenupdated25 from "../components/buttons/2025/Sevenupdated25"
import Eightupdated25 from "../components/buttons/2025/Eightupdated25"
import Nineupdated25 from "../components/buttons/2025/Nineupdated25"
import Tenupdated25 from "../components/buttons/2025/Tenupdated25"
import Elevenupdated25 from "../components/buttons/2025/Elevenupdated25"
import Twelveupdated25 from "../components/buttons/2025/Twelveupdated25"
import Thirteenupdated25 from "../components/buttons/2025/Thirteenupdated25"
import Forteenupdated25 from "../components/buttons/2025/Forteenupdated25"



const page = () => {



    return (
        <div>
            <div className='md:grid md:grid-cols-4 font-serif md:p-10'>

                
          {/* muhs mandate 22 23 */}  
          <div className='shadow-black shadow-lg p-2'>
          <div className='text-center text-2xl'>MUHS Mandate 2022-2023</div>
          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Seat Matrix</p>
              <div>
                  <One />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Infrastructure Details</p>
              <div>
                  <Two />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Trust Deed and Rgistration Certificate</p>
              <div>
                  <Three />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Library Details</p>
              <div>
                  <Four />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Clinical Material In Hospital</p>
              <div>
                  <Five />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Details of Equipment and Instruments</p>
              <div>
                  <Six />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Total Teaching Staff Information as per MSR</p>
              <div>
                  <Seven />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Total Subject Wise Teachers List</p>
              <div>
                  <Eight />
              </div>
          </div>

          <div className=' text-center  md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Total Non-teaching Staff and Ancillary Services Information as per MSR</p>
              <div>
                  <Nine />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Details of Part Time Teachers, Guest Faculty</p>
              <div>
                  <Ten />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Information of Workshops, Activities perfomed in last one year</p>
              <div>
                  <Eleven />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Information of Biometrics Attendance, Research Articles, Students Welfare Schemes</p>
              <div>
                  <Twelve />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>AISHE Certificate</p>
              <div>
                  <Thirteen />
              </div>
          </div>

          <div className=' text-center   md:flex-col md:items-center md:justify-center'>
              <p className='mb-2'>Declaration by Principal of the College</p>
              <div>
                  <Forteen />
              </div>
          </div>

      </div>



          {/* muhs mandate 23 24*/}

          <div className='shadow-black shadow-lg mt-6 md:mt-0 p-2'>
                    <div className='text-center text-2xl'>MUHS Mandate 2023-2024</div>
                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Seat Matrix</p>
                        <div><Oneupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Infrastructure Details</p>
                        <div><Twoupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Trust Deed and Rgistration Certificate</p>
                        <div><Threeupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Library Details</p>
                        <div><Fourupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Clinical Material In Hospital</p>
                        <div><Fiveupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Details of Equipment and Instruments</p>
                        <div><Sixupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Teaching Staff Information as per MSR</p>
                        <div><Sevenupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Subject Wise Teachers List</p>
                        <div><Eightupdated /></div>
                    </div>

                    <div className=' text-center  md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Non-teaching Staff and Ancillary Services Information as per MSR</p>
                        <div><Nineupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Details of Part Time Teachers, Guest Faculty</p>
                        <div><Tenupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Information of Workshops, Activities perfomed in last one year</p>
                        <div><Elevenupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Information of Biometrics Attendance, Research Articles, Students Welfare Schemes</p>
                        <div><Twelveupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>AISHE Certificate</p>
                        <div><Thirteenupdated /></div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Declaration by Principal of the College</p>
                        <div><Forteenupdated /></div>
                    </div>

                </div>

                



                <div className='shadow-black mt-6 md:mt-0 shadow-lg p-2'>
                    <div className='text-center text-2xl'>MUHS Mandate 2024-2025</div>
                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Seat Matrix</p>
                        <div>
                            <Oneupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Infrastructure Details</p>
                        <div>
                            <Twoupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Trust Deed and Rgistration Certificate</p>
                        <div>
                            <Threeupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Library Details</p>
                        <div>
                            <Fourupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Clinical Material In Hospital</p>
                        <div>
                            <Fiveupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Details of Equipment and Instruments</p>
                        <div>
                            <Sixupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Teaching Staff Information as per MSR</p>
                        <div>
                            <Sevenupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Subject Wise Teachers List</p>
                        <div>
                            <Eightupdated24 />
                        </div>
                    </div>

                    <div className=' text-center  md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Total Non-teaching Staff and Ancillary Services Information as per MSR</p>
                        <div>
                            <ZeroNineupdate24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Guest Faculty</p>
                        <div>
                            <Tenupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Webinar Workshop</p>
                        <div>
                            <Elevenupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Information of Biometrics Attendance, Research Articles, Students Welfare Schemes</p>
                        <div>
                            <Twelveupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>AISHE Certificate</p>
                        <div>
                            <Thirteenupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Declaration by Principal of the College</p>
                        <div>
                            <Forteenupdated24 />
                        </div>
                    </div>

                    <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                        <p className='mb-2'>Non Teaching Staff</p>
                        <div>
                            <Nineupdated24 />
                        </div>
                    </div>

                </div>

                <div className='shadow-black mt-6 md:mt-0 shadow-lg p-2'>
                <div className='text-center text-2xl'>MUHS Mandate 2025-2026</div>
                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Seat Matrix</p>
                    <div>
                        <Oneupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Infrastructure Details</p>
                    <div>
                        <Twoupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Trust Deed and Rgistration Certificate</p>
                    <div>
                        <Threeupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Library Details</p>
                    <div>
                        <Fourupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Clinical Material In Hospital</p>
                    <div>
                        <Fiveupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Details of Equipment and Instruments</p>
                    <div>
                        <Sixupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Total Teaching Staff Information as per MSR</p>
                    <div>
                        <Sevenupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Total Subject Wise Teachers List</p>
                    <div>
                        <Eightupdated25 />
                    </div>
                </div>

                <div className=' text-center  md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'> Non-teaching Staff </p>
                    <div>
                        <Nineupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Guest Faculty</p>
                    <div>
                        <Tenupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Webinar Workshop</p>
                    <div>
                        <Elevenupdated25 />
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Information of Biometrics Attendance, Research Articles, Students Welfare Schemes</p>
                    <div>
                        <Twelveupdated25/>
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>AISHE Certificate</p>
                    <div>
                        <Thirteenupdated25/>
                    </div>
                </div>

                <div className=' text-center   md:flex-col md:items-center md:justify-center'>
                    <p className='mb-2'>Declaration by Principal of the College</p>
                    <div>
                        <Forteenupdated25 />
                    </div>
                </div>

                

            </div>




            </div>
        </div>
    )
}

export default page



                




                